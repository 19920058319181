import React, { useContext } from "react";
import { Step, StepLabel, Stepper, styled } from "@mui/material";
import useContentful from "../hooks/useContentful";
import { TypeStepper } from "../types/contentful";
import { LanguageContext } from "../provider/language-provider";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(1),
}));

interface StepperProps {
  activeStep: number;
}

const CustomStepper = ({ activeStep }: StepperProps) => {
  const { language } = useContext(LanguageContext)!;
  const fields = (useContentful("stepper") as TypeStepper<"WITH_ALL_LOCALES">).fields;

  if (!fields) {
    return <div></div>;
  }

  return (
    <StyledStepper activeStep={activeStep}>
      <Step>
        <StepLabel>{fields.select && fields.select[language]}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{fields.focus && fields.focus[language]}</StepLabel>
      </Step>
      <Step>
        <StepLabel>{fields.adjust && fields.adjust[language]}</StepLabel>
      </Step>
    </StyledStepper>
  );
};

export default CustomStepper;
