import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../components/application-wrapper";
import header_image from "../ressources/favi_256.png";

const NotAuthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <PageWrapper>
      <img
        src={header_image} // Replace with your logo path
        alt="Logo"
        style={{ width: "150px", height: "150px" }}
      />
      <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
        Not Authorized
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        You do not have permission to view this page.
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleGoBack}>
          Go Back
        </Button>
      </Box>
    </PageWrapper>
  );
};

export default NotAuthorized;
