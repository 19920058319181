import { Typography } from "@mui/material";
import useContentful from "../hooks/useContentful";
import { TypeNoValues } from "../types/contentful";
import LoadingWrapper from "./pageLoading";
import PageWrapper from "../components/application-wrapper";
import { LanguageContext } from "../provider/language-provider";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { StyledButton } from "../components/customs";
import { useContext } from "react";
import { getDescription } from "../components/richt-text-contentful";

const NoValues = () => {
  const { language } = useContext(LanguageContext)!;
  const fields = (useContentful("noValues") as TypeNoValues<"WITH_ALL_LOCALES">).fields;

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <PageWrapper>
      <br />
      <br />
      <br />
      <Typography variant="h3" align="center">
        {fields.title && fields.title[language]}
      </Typography>

      <Typography component="div">
        {getDescription(fields?.description ? fields.description[language] : null)}
      </Typography>
      <StyledButton variant="contained" href={"/select"}>
        {fields.button && fields.button[language]}
      </StyledButton>
    </PageWrapper>
  );
};

export default NoValues;
