import { Button, styled, Typography } from "@mui/material";
import { bgMainColor, borderRadiusLarge } from "../theme";

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

export const StyledButtonInverted = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  backgroundColor: "black",
  color: bgMainColor,
  "&:hover": {
    backgroundColor: bgMainColor,
    color: "black",
  },
}));

export const StyledButtonValue = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(2, 0),
  },
  //  ...theme.typography.h5,
  border: "1px solid",
  borderRadius: borderRadiusLarge,
  fontSize: "1.5rem",
  "@media (hover: none)": {
    "&:hover": {
      backgroundColor: bgMainColor,
      borderColor: theme.palette.secondary.main,
      border: "1px solid",
      color: theme.palette.secondary.main,
    },
    "&:active": {
      backgroundColor: bgMainColor,
      borderColor: theme.palette.secondary.main,
      border: "1px solid",
      color: theme.palette.secondary.main,
      // Reset active styles
    },
  },
}));

export const StyledTypographyValue = styled(Typography)`
  ${(props) => props.theme.typography.h4};
  ${(props) => props.theme.breakpoints.up("md")} {
    ${(props) => props.theme.typography.h2};
  }
`;
