import { useContext, useEffect, useMemo, useState } from "react";
import { AppUser, AppUserDoc, AppUsersDict, EMPTY_APP_USER } from "../types/users";
import { ValueContext } from "../provider/value-provider";
import { AuthContext } from "../provider/auth-provider";
import {
  isUserRegistered,
  loadAppUsers,
  deleteUser,
  storeAppUser,
  createUser,
} from "../data-functions/users-api";
import { isEqual } from "lodash";
import { loadResults } from "../data-functions/values-api";
import { getComparator, Order } from "../utils/tables";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { EnhancedTableHead } from "../components/enhanced-table";
import { Delete as DeleteIcon, CheckCircleOutlineOutlined as CheckIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledButton } from "../components/customs";
import { formatDateTime } from "../utils/common";
import StarEvaluation from "../components/star-evaluation";
import { snackNotification } from "../provider/snack-provider";
import LoadingWrapper from "./pageLoading";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  //   {
  //     id: "birthYear",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "GJ",
  //     sortable: true,
  //   },
  //   {
  //     id: "gender",
  //     numeric: false,
  //     disablePadding: false,
  //     label: "Geschlecht",
  //     sortable: true,
  //   },
  //   {
  //     id: "company",
  //     numeric: false,
  //     disablePadding: false,
  //     label: "Unternehmen",
  //     sortable: true,
  //   },
  {
    id: "isAdmin",
    numeric: false,
    disablePadding: false,
    label: "Admin",
    sortable: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
  },
];

const EMPTY_ERRORS = {
  name: "",
  surname: "",
  email: "",
  error: "",
  birthYear: "",
  gender: "",
  company: "",
  department: "",
  note1: "",
  note2: "",
};

const UsersPage = () => {
  const [users, setUsers] = useState<AppUsersDict>({});
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("value");
  const [selectedUser, setSelectedUser] = useState<AppUserDoc | null>(null);

  const [errors, setErrors] = useState({ ...EMPTY_ERRORS });

  const { values, initValues, initialized } = useContext(ValueContext)!;
  const { currentUser, isLoading } = useContext(AuthContext)!;
  const { triggerSnackbar } = useContext(snackNotification)!;

  const isAdmin = currentUser?.appUser && currentUser.appUser.isAdmin;

  // ------------------- Component Hooks -------------------
  useEffect(() => {
    async function loadUsers() {
      const newValues = await loadAppUsers(currentUser?.appUser);
      setUsers(newValues);
    }

    if (currentUser?.appUser) loadUsers();
  }, [currentUser]);

  useEffect(() => {
    if (initialized && (!values || values.length === 0)) {
      initValues();
    }
  }, [initialized, values]);

  // ------------------- general functions -------------------
  const displayName = (user: AppUser) => {
    return user ? (user.name + " " + user.surname).trim() : "k.A.";
  };

  const validate = () => {
    const newErrors = { ...EMPTY_ERRORS };
    let isFormValid = true;
    if (!selectedUser) {
      return;
    }
    if (!selectedUser.user.name) {
      newErrors.name = "Bitte geben Sie einen Vornamen ein.";
      isFormValid = false;
    }

    if (!selectedUser.user.surname) {
      newErrors.surname = "Bitte geben Sie einen Nachnamen ein.";
      isFormValid = false;
    }

    if (!selectedUser.user.email) {
      newErrors.email = "Bitte geben Sie eine E-Mail Adresse ein.";
      isFormValid = false;
    }

    setErrors(newErrors);
    return isFormValid;
  };

  const handleAddUser = async () => {
    const newUser: AppUser = {
      ...EMPTY_APP_USER,
    };
    setSelectedUser({ id: "", user: newUser });
  };

  const handleSelectUser = async (userId: string) => {
    if (!userId) {
      return;
    }

    if (selectedUser && selectedUser.id !== userId) {
      const s1User = selectedUser.id ? { ...users[selectedUser.id] } : null;
      const s2User = { ...selectedUser.user };
      delete s2User.results;
      if (s1User) {
        delete s1User.results;
      }

      console.log("user1: ", s1User);
      console.log("user2: ", s2User);

      if (!isEqual(s1User, s2User)) {
        const conf = window.confirm(
          "The selected user has been changed! Press OK if you want to discard the changes."
        );
        if (!conf) {
          return;
        }
      }
    }

    const user = { ...users[userId] };
    if (userId) {
      user.results = await loadResults(userId);
    }
    setSelectedUser({ id: userId, user });
  };

  const handleDeleteUser = async (userId: string) => {
    if (window.confirm("Are you sure you want to delete this value?")) {
      // should deactive user instead of deleting
      const user = users[userId];
      if (!user) {
        return;
      }
      const result = await deleteUser(user.id);
      if (result === "success") {
        const newUsers: AppUsersDict = { ...users };
        delete newUsers[userId];
        setUsers(newUsers);
        setSelectedUser(null);
      } else {
        triggerSnackbar({
          message: "Der Nutzer konnte nicht gelöscht werden!",
          closeFn: () => {},
        });
      }
      //   if (selectedValue && selectedValue.getId() === valueId) {
      //     setSelectedValue(null);
      //   }
    }
  };

  const handleSave = async () => {
    if (selectedUser) {
      if (!validate()) {
        return;
      }

      const newUser = { ...selectedUser.user };
      newUser.email = newUser.email.toLowerCase().trim();

      if (selectedUser.id) {
        const savedUser = await storeAppUser(
          currentUser?.appUser,
          { id: selectedUser.id, user: newUser },
          true
        );
        if (!savedUser) {
          triggerSnackbar({
            message: "Der Nutzer konnte nicht gespeichert werden!",
            closeFn: () => {},
          });
          return;
        } else {
          const newUsers = { ...users };
          newUsers[savedUser.id] = newUser;
          setUsers(newUsers);
          setSelectedUser(savedUser);
          triggerSnackbar({
            message: "Die Nutzerdaten wurden gespeichert...",
            closeFn: () => {},
          });
        }
      } else {
        const isExisting = await isUserRegistered(selectedUser.user.email);
        if (isExisting) {
          const newErrors = { ...errors };
          newErrors.email = "Diese E-Mail Adresse ist bereits registriert.";
          setErrors(newErrors);
          return;
        }

        const newId = await createUser(currentUser?.appUser, selectedUser.user);
        if (newId.status === "OK" && newId.id) {
          const newUsers = { ...users };
          newUsers[newId.id] = newUser;
          setUsers(newUsers);
          setSelectedUser({ id: newId.id, user: newUser });
          triggerSnackbar({
            message: "Ein neuer Nutzer wurde erstellt!",
            closeFn: () => {},
          });
        } else {
          triggerSnackbar({
            message: "Der Nutzer konnte nicht gespeichert werden!",
            closeFn: () => {},
          });
        }
      }
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    console.log("target name: ", name);

    if (selectedUser) {
      const newSelectedUser = {
        ...selectedUser,
        user: { ...selectedUser.user, [name]: e.target.value },
      };
      console.log("setting new value: ", newSelectedUser);
      setSelectedUser(newSelectedUser);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getResultUrl = (resultId: string) => {
    return window.location.origin + "/results/" + resultId;
  };

  //   function descendingComparator(a, b, orderBy) {
  //     const value1 = a;
  //     const value2 = b;

  //     // console.log("a1: ");
  //     // console.log(value1);

  //     // console.log("a2: ");
  //     // console.log(value2);

  //     // console.log("orderBy: " + orderBy);

  //     if (value1 && value2) {
  //       let res = 0;
  //       switch (orderBy) {
  //         case "value":
  //           if (value2.value < value1.value) {
  //             res = -1;
  //           }
  //           if (value2.value > value1.value) {
  //             res = 1;
  //           }

  //           return res;

  //         default:
  //           break;
  //       }
  //     } else {
  //       if (value1 && !value2) {
  //         return -1;
  //       } else if (!value1 && value2) {
  //         return 1;
  //       }
  //     }
  //     return 0;
  //   }

  const filteredValues = useMemo(() => {
    if (!users) return [];
    return Object.entries(users).filter(([userId, user]) => {
      const sv = searchValue.toLocaleLowerCase();
      return (
        sv.length < 3 ||
        (user &&
          (displayName(user).toLocaleLowerCase().includes(sv) ||
            (user.email && user.email.toLocaleLowerCase().includes(sv))))
      );
    });
  }, [searchValue, users]);

  const visibleRows = useMemo(
    () => filteredValues.slice().sort(getComparator(order, orderBy as keyof AppUser)),
    [order, orderBy, filteredValues]
  );

  return (
    <Box
      sx={{
        margin: "1.5rem",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        User Management
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        {isAdmin && (
          <Box>
            <Box display={"flex"} sx={{ gap: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleAddUser}>
                Add New User
              </Button>
              {/* <Button variant="outlined" color="primary" onClick={handleTest}>
              Test
            </Button> */}
              <TextField
                variant="outlined"
                size="small"
                placeholder="Suche..."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                sx={{ minWidth: "250px" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
                minHeight: "400px",
                height: "calc(100vh - 310px)",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexBasis: "0",
                  maxWidth: `calc(100% - 450px)`,
                  marginRight: "8px",
                }}
              >
                <TableContainer component={Paper} sx={{ height: "100%" }}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <EnhancedTableHead
                      numSelected={0}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={() => {}}
                      onRequestSort={handleRequestSort}
                      rowCount={visibleRows.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows.map(([userId, user], index) => {
                        return (
                          <TableRow
                            key={`row-${index}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => handleSelectUser(userId)}
                            className={selectedUser?.id === userId ? "selected" : ""}
                          >
                            <TableCell scope="row" size="small">
                              {displayName(user)}
                            </TableCell>
                            {/* <TableCell size="small">{user?.birthYear || "k.A."}</TableCell>
                          <TableCell size="small">{user?.gender || "k.A."}</TableCell>
                          <TableCell size="small">{user?.company || ""}</TableCell> */}
                            <TableCell align="center" size="small">
                              {user?.isAdmin && <CheckIcon />}
                            </TableCell>

                            <TableCell style={{ width: 40 }} size="small">
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDeleteUser(userId);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* ------------- Work Order Details ------------- */}
              <Box sx={{ width: "48%" }}>
                {selectedUser?.user && (
                  <Card sx={{ marginBottom: "1rem" }}>
                    <CardHeader
                      title={
                        selectedUser.id
                          ? displayName(selectedUser.user) + " (" + selectedUser.user.email + ")"
                          : "Neuer Nutzer"
                      }
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.name}
                            helperText={errors.name}
                            fullWidth
                            required
                            label="Vorname"
                            name="name"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.name || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.surname}
                            helperText={errors.surname}
                            fullWidth
                            label="Nachname"
                            name="surname"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.surname || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.email}
                            helperText={errors.email}
                            fullWidth
                            required
                            label="E-Mail Adresse"
                            name="email"
                            variant="outlined"
                            size="small"
                            disabled={selectedUser.id !== ""}
                            value={selectedUser.user.email || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.birthYear}
                            helperText={errors.birthYear}
                            fullWidth
                            label="Geburtsjahr"
                            name="birthYear"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.birthYear || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            error={!!errors.gender}
                            helperText={errors.gender}
                            fullWidth
                            label="Geschlecht"
                            name="gender"
                            variant="outlined"
                            select
                            size="small"
                            value={selectedUser.user.gender || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          >
                            <MenuItem value="M">Männlich</MenuItem>
                            <MenuItem value="F">Weiblich</MenuItem>
                            <MenuItem value="D">Divers</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.company}
                            helperText={errors.company}
                            fullWidth
                            label="Unternehmen"
                            name="company"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.company || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            error={!!errors.department}
                            helperText={errors.department}
                            fullWidth
                            label="Abteilung"
                            name="department"
                            variant="outlined"
                            size="small"
                            value={selectedUser.user.department || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={!!errors.note1}
                            helperText={errors.note1}
                            fullWidth
                            label="Notiz 1"
                            name="note1"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            value={selectedUser.user.note1 || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            error={!!errors.note2}
                            helperText={errors.note2}
                            fullWidth
                            label="Notiz 2"
                            name="note2"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            value={selectedUser.user.note2 || ""}
                            onChange={handleChangeValue}
                            sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions sx={{ padding: 2 }}>
                      <Box flexGrow={1}></Box>
                      <StyledButton variant="contained" sx={{ mr: 2 }} onClick={handleSave}>
                        Speichern
                      </StyledButton>
                      <StyledButton
                        variant="outlined"
                        onClick={() => {
                          setSelectedUser(null);
                        }}
                        sx={{ mr: 2 }}
                      >
                        Abbrechen
                      </StyledButton>
                    </CardActions>
                  </Card>
                )}
                {selectedUser?.id && selectedUser.user.results && (
                  <Card>
                    <CardHeader title="Test Ergebnisse" />
                    <CardContent>
                      {!selectedUser.user.results ||
                        (selectedUser.user.results.size === 0 && (
                          <Typography variant="body2">Noch keine Ergebnisse vorhanden.</Typography>
                        ))}
                      {Array.from(selectedUser.user.results.entries()).map(([id, result]) => {
                        return (
                          <Accordion key={id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography component="div">
                                  {formatDateTime(result.createdAt?.seconds)}
                                </Typography>
                                <Link href={getResultUrl(id)} target="_blank">
                                  <Typography component="div">{getResultUrl(id)}</Typography>
                                </Link>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails sx={{ display: "block" }}>
                              <StarEvaluation focusedValues={result.values} />
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!isAdmin && (
          <>
            <p>This is a protected page. Only users with the admin role can access it.</p>
          </>
        )}
      </Box>
    </Box>
  );
};

export default UsersPage;
