import { createContext, ReactNode, useEffect, useState } from "react";
import { DEFAULT_LANGUAGE, isLanguageSupported, Language } from "../types/languages";

interface LanguageProviderProps {
  children: ReactNode;
}

interface LanguageContextType {
  language: Language;
  setLanguage: (language: Language) => void;
}

export const LanguageContext = createContext<LanguageContextType | null>(null);

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLang] = useState<Language>(DEFAULT_LANGUAGE);

  useEffect(() => {
    console.log("loading language...");
    const lang = localStorage.getItem("values-lang");
    if (lang) {
      setLang(lang as Language);
    } else {
      const lng = navigator.language.split(/[-_]/)[0];
      if (isLanguageSupported(lng as Language)) {
        setLang(lng as Language);
      } else {
        setLang(DEFAULT_LANGUAGE);
      }
    }
  }, []);

  const setLanguage = (language: Language) => {
    let newLang = language;
    if (!isLanguageSupported(language)) {
      newLang = DEFAULT_LANGUAGE;
    }
    setLang(newLang);
    localStorage.setItem("values-lang", newLang);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
