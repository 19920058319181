export type Language = "de" | "en";
export const SUPPORTED_LANGUAGES: Language[] = ["de", "en"];
export const languageMappping = {
  en: "gb",
  de: "de",
};

export const DEFAULT_LANGUAGE: Language = "de";

export const isLanguageSupported = (language: string): boolean => {
  return SUPPORTED_LANGUAGES.includes(language as Language);
};
