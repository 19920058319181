import { useContext } from "react";
import PageWrapper from "../components/application-wrapper";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "./pageLoading";
import { LanguageContext } from "../provider/language-provider";
import { Box, Typography } from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { TypeHome } from "../types/contentful";
import { StyledButton } from "../components/customs";
import { useNavigate } from "react-router-dom";
import { ValueContext } from "../provider/value-provider";
import { getDescription } from "../components/richt-text-contentful";

const StartPage = () => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext)!;
  const { initValues } = useContext(ValueContext)!;
  const fields = (useContentful("home") as TypeHome<"WITH_ALL_LOCALES">).fields;

  const handleStartClick = () => {
    initValues();
    navigate("/select");
  };

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <PageWrapper>
      <Typography variant="h3" align="center">
        {fields.title && fields.title[language]}
      </Typography>
      <Typography component="div">
        {getDescription(fields?.description ? fields.description[language] : null)}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <StyledButton variant="contained" color="primary" onClick={handleStartClick}>
          {fields.button && fields.button[language]}
        </StyledButton>
      </Box>
    </PageWrapper>
  );
};

export default StartPage;
