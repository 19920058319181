import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";

export const getDescription = (doc: Document | null | undefined) => {
  if (doc) {
    return documentToReactComponents(doc);
  } else {
    return <></>;
  }
};
