import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useContentful from "../hooks/useContentful";
import { TypeAdjust } from "../types/contentful";
import { LanguageContext } from "../provider/language-provider";
import { ValueContext } from "../provider/value-provider";
import env from "../env-config";
import { Value } from "../types/values";
import LoadingWrapper from "./pageLoading";
import PageWrapper from "../components/application-wrapper";
import CustomStepper from "../components/custom-stepper";
import Spacer from "../components/spacer";
import { Chip, Grid, Grow, Typography } from "@mui/material";
import { DEFAULT_LANGUAGE } from "../types/languages";
import { getDescription } from "../components/richt-text-contentful";
import LinearProgressWithLabel from "../components/linear-progress-with-label";
import { StyledButton } from "../components/customs";

const AdjustPage = () => {
  const fields = (useContentful("adjust") as TypeAdjust<"WITH_ALL_LOCALES">).fields;

  const navigate = useNavigate();
  const { language } = useContext(LanguageContext)!;
  const { values, handleUpdate } = useContext(ValueContext)!;

  const selected = values.filter((e) => e.selected);

  const max = env.adjusted.max;
  const adjusted = values.filter((e) => e.adjusted);
  const progress = (adjusted.length / max) * 100;
  const done = adjusted.length === max;

  function onDelete(value: Value) {
    if (value.adjusted) {
      handleUpdate(value.id, { adjusted: false });
    }
  }
  function onClick(value: Value) {
    handleUpdate(value.id, { adjusted: true });
  }
  function disabled(value: Value) {
    return !value.adjusted && adjusted.length >= max;
  }

  function color(value: Value) {
    switch (true) {
      case value.adjusted:
        return "primary";
      case value.selected:
        return "default";
      default:
        return "default";
    }
  }

  if (values.length === 0) {
    navigate("/");
  }

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <PageWrapper>
      <CustomStepper activeStep={0} />
      <Spacer />
      <Typography variant="h6">{fields.title && fields.title[language]}</Typography>
      <Spacer />

      <Spacer />
      <Grid container spacing={1}>
        {selected.map((value, index) => {
          const translation = value.translations[language]
            ? value.translations[language].value
            : value.translations[DEFAULT_LANGUAGE].value;
          return (
            <Grid item xs={6} sm={3} key={translation} container>
              <Grow in={true} timeout={100 + index * 20}>
                <Chip
                  disabled={disabled(value)}
                  label={translation}
                  onDelete={() => {
                    onDelete(value);
                  }}
                  onClick={() => {
                    onClick(value);
                  }}
                  color={color(value)}
                />
              </Grow>
            </Grid>
          );
        })}
      </Grid>
      <Spacer />
      <Spacer />

      <Typography variant="h6">
        {getDescription(fields?.description ? fields.description[language] : null)}
      </Typography>
      <LinearProgressWithLabel value={progress} />
      <StyledButton
        variant="contained"
        color="primary"
        disabled={!done}
        onClick={() => navigate("/focus")}
      >
        {fields.button && fields.button[language]}
      </StyledButton>
    </PageWrapper>
  );
};

export default AdjustPage;
