import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../types/firebase";

export const isUserRegistered = async (email: string) => {
  try {
    const docRef = query(collection(db, "users"), where("email", "==", email.toLowerCase()));
    const docSnap = await getDocs(docRef);
    console.log("users found: ", docSnap.docs);
    return docSnap.docs.length > 0;
  } catch (e) {
    console.log("Error getting  document:", e);
    return false;
  }
};
