import React, { useContext, useEffect } from "react";

import { List, ListItem, ListItemSecondaryAction, Rating } from "@mui/material";
import { styled } from "@mui/system";
import { Value, Values } from "../types/values";
import { LanguageContext } from "../provider/language-provider";
import { ValueContext, ValueProvider } from "../provider/value-provider";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)(({ theme }) => ({
  right: 0,
}));

interface StarEvaluationProps {
  focusedValues: Values;
}

const StarEvaluation = ({ focusedValues }: StarEvaluationProps) => {
  const { language } = useContext(LanguageContext)!;
  const { values, initialized, initValues, getValue } = useContext(ValueContext)!;

  const [focused, setFocused] = React.useState<Values>([]);

  useEffect(() => {
    if (initialized && (!values || values.length === 0)) {
      initValues();
    }
  }, [initialized, values]);

  useEffect(() => {
    if (values && language && focusedValues) {
      const fcs = focusedValues
        .filter((e: Value) => e.adjusted)
        .sort(function (a: Value, b: Value) {
          if (a.focused > b.focused) return -1;
          if (a.focused < b.focused) return 1;
          return 0;
        });
      setFocused(fcs);
    }
  }, [values, language, focusedValues]);

  return (
    <List>
      {focused.length > 0 &&
        focused.map((value: Value, index) => {
          const cValue = getValue(value.id);
          let valueName = "";
          let valueId = "";
          let old = false;
          if (cValue) {
            valueName = cValue.translations[language].value;
            valueId = cValue.id;
          } else {
            valueName = value.translations[language].value;
            valueId = "vid_" + index;
            old = true;
          }
          return (
            <StyledListItem
              sx={{ fontStyle: old ? "italic" : "normal", color: old ? "gray" : "black" }}
              key={valueId}
            >
              {valueName}
              <StyledListItemSecondaryAction>
                <Rating
                  readOnly
                  value={value.focused}
                  max={value.focused > 0 ? value.focused : 1}
                />
              </StyledListItemSecondaryAction>
            </StyledListItem>
          );
        })}
    </List>
  );
};

export default StarEvaluation;
