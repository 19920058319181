import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAtpupxwUeDk5xCrgSFvwhxI2bE70qA22I",
  authDomain: "valueverse-36892.firebaseapp.com",
  projectId: "valueverse-36892",
  storageBucket: "valueverse-36892.appspot.com",
  messagingSenderId: "903334055209",
  appId: "1:903334055209:web:e303a58528cc412e27bb38",
  measurementId: "G-S79KZSK9KD",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const REGION = "europe-west3";

const db = getFirestore(app);
export const storage = getStorage(app);

export const functions = getFunctions(app, REGION);

export const BASE_FUNCTIONS_URL = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net/api`;

if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export default db;
