import { Paper, styled } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

interface PageWrapperProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  // Define your default styles here
  minWidth: "320px",
  maxWidth: "1024px",
  boxShadow: "none",
  margin: theme.spacing(1, "auto"),
  padding: theme.spacing(1),
  minHeight: "300px",
  borderRadius: "15px",
  border: "1px solid",
  borderColor: "black",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(2),
    minHeight: "500px",
  },
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(1, 2),
    width: "calc(100% - 32px)",
  },
}));

const PageWrapper: React.FC<PageWrapperProps> = ({ children, sx }) => {
  return <StyledPaper sx={sx}>{children}</StyledPaper>;
};

export default PageWrapper;
