import { ReactNode, useContext } from "react";
import { useEffect, useState, createContext } from "react";
import { Result, Results, Value, Values } from "../types/values";
import { loadResultById, loadResults, loadValues } from "../data-functions/values-api";
import { AuthContext } from "./auth-provider";
import { cloneValues, shuffleArray } from "../utils/common";
import { v4 as uuidv4 } from "uuid";
import { Timestamp } from "firebase/firestore";

interface ValueProviderProps {
  children: ReactNode;
}

interface ValueContextType {
  values: Values;
  results: Results;
  initialized: boolean;
  initValues: () => void;
  setValues: (values: Values) => void;
  handleUpdate: (valueId: string, updateAttributes: any) => void;
  getValue: (valueId: string) => Value | undefined;
  bumpSelectedToAdjusted: () => void;
  persistValues: () => void;
  getResultById: (resultId: string) => Promise<Result | null>;
  getResultsByUserId: (userId: string) => Promise<Results>;
}

export const ValueContext = createContext<ValueContextType | null>(null);

export const ValueProvider = ({ children }: ValueProviderProps) => {
  const [values, setValues] = useState<Values>([]);
  const [initialized, setInitialized] = useState(false);
  const [loadedValues, setLoadedValues] = useState<Values>([]);
  const [results, setResults] = useState<Results>(new Map());

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const fetchValues = async () => {
      const newValues = await loadValues(true);
      console.log("Loaded " + newValues.size + " values from firestore.");
      setLoadedValues(Array.from(newValues.values()));
      setInitialized(true);
    };

    fetchValues();
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      if (!currentUser || !currentUser.appUser) {
        return;
      }
      const newResults = await loadResults(currentUser.appUser?.id);
      console.log("Loaded " + newResults.size + " results from firestore.");
      setResults(newResults);
    };
    fetchResults();
  }, [currentUser]);

  const initValues = () => {
    const initValues = loadedValues.map((value) => {
      const vl = new Value(value.id, value.translations);

      return vl;
    });

    setValues(shuffleArray(initValues));
  };

  const handleUpdate = (valueId: string, updatedAttributes: any) => {
    // console.log("update value");
    // console.log(value);
    // console.log(updatedAttributes);

    const temp = cloneValues(values);
    const index = temp.findIndex((e) => e.id === valueId);
    if (index > -1) {
      const next = temp[index];
      console.log("updating value: ", next);
      console.log("with attributes: ", updatedAttributes);
      Object.assign(next, updatedAttributes);

      console.log("value updated: ", temp[index]);
      setValues(temp);
    }
  };

  const getValue = (valueId: string) => {
    return [...values].filter((e) => e.id === valueId).pop();
  };

  const bumpSelectedToAdjusted = () => {
    const temp = [...values].map((value) => {
      if (value.selected) {
        value.adjusted = true;
      }
      return value;
    });

    setValues(temp);
  };

  const persistValues = async () => {
    // if (!currentUser?.appUser) {
    //   console.error("No user found to persist values.");
    //   return null;
    // }

    // console.log("persisting values for user: ", currentUser.appUser.id);
    let result: Result | undefined | null = {
      id: "",
      //      createdBy: currentUser.appUser.id,
      createdAt: Timestamp.now(),
      createdBy: "test",
      values: cloneValues(values),
    };

    //    result = await storeResult(result);
    result.id = uuidv4();
    if (result?.id) {
      results.set(result.id, result);
      setResults(results);
    }
    return result?.id;
  };

  const getResultById = async (resultId: string) => {
    let result: Result | undefined | null = results.get(resultId);
    if (!result) {
      result = await loadResultById(resultId);
    }
    return result || null;
  };

  const getResultsByUserId = async (userId: string) => {
    return await loadResults(userId);
  };

  return (
    <ValueContext.Provider
      value={{
        values,
        initialized,
        results,
        initValues,
        setValues,
        handleUpdate,
        getValue,
        bumpSelectedToAdjusted,
        persistValues,
        getResultById,
        getResultsByUserId,
      }}
    >
      {children}
    </ValueContext.Provider>
  );
};
