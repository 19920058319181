import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Result } from "../types/values";
import useContentful from "../hooks/useContentful";
import { TypeResults } from "../types/contentful";
import { AuthContext } from "../provider/auth-provider";
import { ValueContext } from "../provider/value-provider";
import LoadingWrapper from "./pageLoading";
import PageWrapper from "../components/application-wrapper";
import { Divider, Typography } from "@mui/material";
import { LanguageContext } from "../provider/language-provider";
import Spacer from "../components/spacer";
import { getDescription } from "../components/richt-text-contentful";
import { StyledButton } from "../components/customs";
import StarEvaluation from "../components/star-evaluation";

const ResultPage = () => {
  const { id } = useParams();

  const [result, setResult] = useState<Result | null>(null);

  const fields = (useContentful("results") as TypeResults<"WITH_ALL_LOCALES">).fields;

  const navigate = useNavigate();
  const { language } = useContext(LanguageContext)!;
  const { currentUser } = useContext(AuthContext)!;
  const { getResultById } = useContext(ValueContext)!;

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        return false;
      }

      const newResult = await getResultById(id);
      setResult(newResult);
    };

    fetchData();
  }, [id]);

  if (!result || !fields) {
    return <LoadingWrapper />;
  }

  const isAuthedAuthor =
    result.createdBy === currentUser?.authUser?.uid && currentUser?.authUser?.uid !== undefined;

  return (
    <PageWrapper>
      {/* {(isTempAuthor || isAuthedAuthor) && <CustomStepper activeStep={2} />} */}
      <Typography variant="h6">
        {fields.title && fields.title[language]}
        {/* {formatDate(result.createdAt.seconds)} */}
      </Typography>
      <StarEvaluation focusedValues={result.values} />

      {isAuthedAuthor && (
        <>
          <Divider />
          <Spacer />
          <Typography variant="h6">{fields.shareTitle && fields.shareTitle[language]}</Typography>
          <Typography component="div">
            {getDescription(fields.shareDescription ? fields.shareDescription[language] : null)}
          </Typography>

          <StyledButton
            variant="contained"
            color="primary"
            // target="_blank"
            href={`mailto:?subject=${fields.emailSubject}&body=${window.location.href}`}
          >
            {fields.buttonEmailValues && fields.buttonEmailValues[language]}
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            // target="_blank"
            href={`mailto:?to=${fields.emailAddressBen}&subject=${fields.emailSubject}&body=${window.location.href}`}
            sx={{ marginLeft: "1em" }}
          >
            {fields.buttonEmailToBen && fields.buttonEmailToBen[language]}
          </StyledButton>
          <Spacer />
        </>
      )}
      {isAuthedAuthor && (
        <>
          <Divider />
          <Spacer />
          <Typography variant="h6">{fields.savedTitle && fields.savedTitle[language]}</Typography>
          <Typography component="div">
            {getDescription(fields.savedDescription ? fields.savedDescription[language] : null)}
          </Typography>{" "}
          <StyledButton variant="contained" color="primary" onClick={() => navigate("/profile")}>
            {fields.savedButton && fields.savedButton[language]}
          </StyledButton>
          <Spacer />
        </>
      )}
    </PageWrapper>
  );
};

export default ResultPage;
