import { Box, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { AuthContext } from "../provider/auth-provider";
import { useNavigate } from "react-router-dom";
import useContentful from "../hooks/useContentful";
import { StyledButtonInverted } from "../components/customs";

const HomePage = () => {
  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const handleStartClick = () => {
    if (currentUser?.appUser) {
      navigate("/start");
    } else {
      navigate("/register");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "1.25em",
        paddingRight: "1.25em",
      }}
    >
      <Box
        sx={{
          maxWidth: "44.25em",
          marginTop: "1.5em",
          marginBottom: "5em",
        }}
      >
        <Typography variant="h1" sx={{ mt: 2 }}>
          Valueverse
        </Typography>
        <Typography variant="h2" sx={{ mt: 2 }}>
          Some Valueverse subtitle
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Eine gesunde Unternehmenskultur ist der wichtigste wirtschaftliche Erfolgstreiber. Wir
          machen übergreifend Werte sichtbar und ermöglichen eine stärkere Identifikation von
          Mitarbeitenden mit Ihrem Unternehmen.
        </Typography>
      </Box>
      <Box>
        <StyledButtonInverted
          sx={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
          onClick={handleStartClick}
        >
          Starte deine Value Exploration
        </StyledButtonInverted>
      </Box>
    </Box>
  );
};

export default HomePage;
