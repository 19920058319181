import { ReactElement } from "react";

export type Order = "asc" | "desc";

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  icon?: ReactElement;
  tooltip?: string;
  width?: string;
}

export type HeadCells = HeadCell[];

function defaultStringComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const c1 = a[orderBy];
  const c2 = b[orderBy];

  if (!orderBy) {
    return 0;
  }

  let res = 0;
  if (c1 && c2) {
    if (c2 < c1) {
      res = -1;
    }
    if (c2 > c1) {
      res = 1;
    }
  } else {
    if (c1 && !c2) {
      res = -1;
    } else if (!c1 && c2) {
      res = 1;
    }
  }

  return res;
}

export function getComparator<T>(
  order: Order,
  orderBy: keyof T,
  descendingComparator?: (a: T, b: T, orderBy: keyof T) => number
): (a: T, b: T) => number {
  const comparator = descendingComparator || defaultStringComparator;
  return order === "desc"
    ? (a, b) => comparator(a, b, orderBy)
    : (a, b) => -comparator(a, b, orderBy);
}
