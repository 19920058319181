import { useEffect, useState } from "react";
import { Value, Values } from "../types/values";
import { shuffleArray } from "../utils/common";

const usePairedValues = (values: Values) => {
  const [paired, setPaired] = useState<[Value, Value][]>([]);
  const adjusted = values.filter((e) => e.adjusted);

  useEffect(() => {
    console.log("calling usePairedValues with changed values");
    if (paired.length > 0) {
      // do not shuffle multiple times.
      return;
    }

    let hashes: string[] = [];

    const generated = adjusted.map((firstValue) => {
      return adjusted
        .filter((e) => firstValue.id !== e.id)
        .map((secondValue) => {
          //check if this pairing is there.
          const hash = [firstValue.id, secondValue.id].sort().toString();
          if (hashes.indexOf(hash) > -1) {
            return null;
          }

          hashes.push(hash);
          //add them shuffled for more randomness.
          return shuffleArray([firstValue, secondValue]);
        });
    });

    //2. flatten
    const flattened = generated.flat().filter((x) => x != null);
    //4. now shuffle the array.
    const pairedArray = shuffleArray(flattened);

    setPaired(pairedArray);
  }, [values]);

  return paired;
};

export default usePairedValues;
