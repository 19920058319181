import { Box, LinearProgress, Typography } from "@mui/material";
import PageWrapper from "../components/application-wrapper";
import Spacer from "../components/spacer";

interface PageLoadingProps {
  title: string;
}

const LoadingWrapper = ({ title = "" }) => {
  return (
    <PageWrapper>
      <Spacer />
      <LinearProgress />
      <LinearProgress color="primary" />
      <Spacer />
      {title && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          marginTop="48px"
        >
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
    </PageWrapper>
  );
};

export default LoadingWrapper;
