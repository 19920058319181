import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../provider/language-provider";
import { createClient } from "contentful";

export default function useContentful(contentType: string) {
  const [item, setItem] = useState({});

  const { language } = useContext(LanguageContext)!;

  const client = createClient({
    space: process.env.REACT_APP_APP_CONTENTFUL_SPACE || "",
    accessToken: process.env.REACT_APP_APP_CONTENTFUL_ACCESS_TOKEN || "",
    environment: process.env.REACT_APP_APP_CONTENTFUL_ENVIRONMENT,
  });

  useEffect(() => {
    const fetchData = async () => {
      console.log(
        "fetching contentful data for language: " + language + " and contentType: " + contentType
      );

      const entries = await client.withAllLocales.getEntries({
        content_type: contentType,
      });

      //      const filtered = entries.items.filter((e) => e.sys.contentType.sys.id === contentType);

      if (entries && entries.items[0]) {
        setItem(entries.items[0]);
      } else {
        console.log("no contentful data found");
        setItem({});
      }
    };

    fetchData();
  }, [contentType]);

  return item;
}
