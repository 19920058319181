import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const headerColor = "black";

export const bgMainColor = "#fff"; // "#f1ede8";
export const borderRadiusSmall = "20px";
export const borderRadiusLarge = "40px";

const theme = createTheme({
  typography: {
    fontFamily: "Switzer, sans-serif",
    h1: {
      fontSize: "4.5rem",
      fontWeight: 600,
      color: "black",
    },
    h2: {
      fontSize: "3.5rem",
      fontWeight: 300,
      color: "black",
    },
    body1: {
      fontSize: "1.2rem",
      lineHeight: "140%",
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "140%",
    },
  },
  palette: {
    primary: { main: "#000" },
    //    secondary: { main: benMainBeige },
    mode: "light",
    background: {
      paper: bgMainColor,
      default: bgMainColor,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@fontFace": "Lato, Helvetica, sans-serif",
        },
      },
    },

    // Butons
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusSmall,
          border: "1px solid",
          borderColor: "black",
          backgroundColor: bgMainColor,
          color: "black",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "16px",
          paddingBottom: "0",
          boxShadow: "none",
          marginBottom: "2em",

          backgroundColor: bgMainColor,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusLarge,
          border: "none",
          borderColor: "black",
          boxShadow: "none",

          width: "100%",
          margin: "auto",
          padding: "0 24px",

          "& .MuiButton-root": {
            marginLeft: "16px",
            borderRadius: borderRadiusSmall,
            border: "1px solid",
            backgroundColor: bgMainColor,
            color: "black",
            boxShadow: "none",
            height: "1.75rem",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "darkgrey",
        },
        bar: {
          backgroundColor: "black",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: "rgba(0, 0, 0, 0.5);",
          },
          "&:hover fieldset": {
            borderColor: "rgba(0, 0, 0, 1);",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          "&::before": {
            borderColor: "black",
            width: "1rem",
          },
          "&::after": {
            borderColor: "black",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            fontWeight: "normal",
          },
          "& .MuiTableCell-head": {
            backgroundColor: headerColor,
            color: "white",
            fontWeight: "bold",
            fontSize: "1rem",
            "& .MuiTableSortLabel-root": {
              backgroundColor: headerColor,
              color: "white",
              fontWeight: "bold",
              fontSize: "1rem",
              "& .MuiTableSortLabel-icon": {
                color: "white",
              },
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.selected": {
            backgroundColor: grey[400],
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: headerColor,
          color: "white",

          "& .MuiTypography-root": {
            fontWeight: "bold",
            fontSize: "1rem",
          },
        },
      },
    },
  },
});

export default theme;
